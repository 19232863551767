/* colours
zinc-950 #09090b
slate-700 334155
sky-600 0284c7
emerald-600 059669
*/

.map-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
